import Layout from '@/components/Layout';
import SEO from '@/components/SEO';
import SearchBox from '@/components/product/SearchBox';
import SearchResult from '@/components/product/SearchResult';
import SpecificationRefinement from '@/components/product/SpecificationRefinement';
import CategoryRefinement from '@/components/supplier/CategoryRefinement';
import MobileFiltersModal from '@/components/supplier/MobileFiltersModal';
import { useIsMobile } from '@/lib';
import { useModal } from '@/lib/modal';
import { FilterIcon } from '@heroicons/react/solid';
import algoliaSearch from 'algoliasearch/lite';
import { PageProps } from 'gatsby';
import * as React from 'react';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Configure, InstantSearch } from 'react-instantsearch-dom';
import { StringParam, useQueryParam } from 'use-query-params';

const searchClient = algoliaSearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
);
const DEFAULT_INDEX = process.env.GATSBY_ALGOLIA_SUPPLIERS_INDEX_NAME;

const SupplierSearchPage: FC<PageProps> = () => {
  const [query, setQuery] = useState('');
  const [savedQuery, setSavedQuery] = useQueryParam('q', StringParam);
  const [currentCategoryQueryParam, setCurrentCategoryQueryParam] =
    useQueryParam('category', StringParam);
  const [searchState, setSearchState] = useState<any>({});
  const debouncedSetStateRef = useRef<number>();
  const isMobile = useIsMobile();
  const mobileFiltersModal = useModal();

  useEffect(() => {
    if (savedQuery) setQuery(savedQuery);

    return () => {
      if (debouncedSetStateRef.current)
        clearTimeout(debouncedSetStateRef.current);
    };
  }, [debouncedSetStateRef]);

  const onSearchStateChange = ({ query: newQuery, menu, ...rest }) => {
    // saved query
    if (debouncedSetStateRef.current)
      clearTimeout(debouncedSetStateRef.current);
    debouncedSetStateRef.current = window?.setTimeout(() => {
      if (savedQuery !== newQuery) {
        setSavedQuery(newQuery);
      }
    }, 400);

    // query
    setQuery(newQuery);
    if (query !== newQuery) {
      setCurrentCategoryQueryParam(undefined);
      setSearchState({});
    } else {
      const newCategoryQueryParam = menu?.category
        ? encodeURIComponent(menu.category)
        : undefined;
      if (currentCategoryQueryParam !== newCategoryQueryParam) {
        setCurrentCategoryQueryParam(newCategoryQueryParam);
      }

      // everything else
      setSearchState(rest);
    }
  };

  const currentCategory = useMemo((): any | null => {
    if (!currentCategoryQueryParam) return null;
    return decodeURIComponent(currentCategoryQueryParam);
  }, [currentCategoryQueryParam]);

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={DEFAULT_INDEX}
      stalledSearchDelay={500}
      searchState={{
        ...searchState,
        query,
        menu: currentCategory
          ? {
              category: currentCategory,
            }
          : undefined,
      }}
      onSearchStateChange={onSearchStateChange}
    >
      <Configure clickAnalytics />
      <Layout
        className="relative"
        fsoHeader
        fsoHeaderOverrideSearchForm={<SearchBox />}
        minimalFooter
      >
        <SEO
          url="ro/cauta-furnizori"
          title={`Cauta ${
            currentCategory?.displayName ?? 'furnizori'
          } HoReCa pentru afacerea ta`}
          description=""
          lang="ro"
        />

        <section className="min-h-screen flex flex-col ">
          <section className="container m-auto w-full min-h-screen flex">
            {!isMobile && (
              <div className="hidden lg:block p-4 min-w-80 max-w-sm">
                <CategoryRefinement
                  attribute="category"
                  label="Categorie"
                  limit={4}
                  showMore
                  showMoreLimit={30}
                />
                <SpecificationRefinement
                  attribute="tags"
                  specLabel="Servicii"
                  limit={4}
                  showMore
                  showMoreLimit={30}
                />
              </div>
            )}

            <SearchResult
              className="flex-1 bg-gray-200"
              currentCategoryName={currentCategory?.displayName}
              hitType="supplier"
            >
              {isMobile && (
                <>
                  <div className="flex gap-2 pb-4">
                    <button
                      className="border rounded-md border-gray-400 bg-white p-2 flex-1 text-primary-300 flex items-center justify-center"
                      type="button"
                      onClick={() => mobileFiltersModal.open()}
                    >
                      <FilterIcon className="h-5 w-5 mr-2" />
                      Filtreaza
                    </button>
                  </div>
                </>
              )}
            </SearchResult>
          </section>
        </section>
      </Layout>

      {isMobile && (
        <>
          <MobileFiltersModal
            control={mobileFiltersModal}
            clearFilters={() => {
              setSearchState({});
              setCurrentCategoryQueryParam(null);
            }}
          />
        </>
      )}
    </InstantSearch>
  );
};

export default SupplierSearchPage;
